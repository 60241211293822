// Font Awesome
// Font icon set

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown,
  faCalendarAlt,
  faClock,
  faLongArrowAltRight,
  faUsers,
  faShip,
  faStar,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faPinterestP,
  faInstagram,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";

$(document).ready(() => {
  library.add(
    faSearch,
    faTimes,
    faAngleRight,
    faPhoneAlt,
    faAngleLeft,
    faMapMarkerAlt,
    faEnvelope,
    faFacebookF,
    faPinterestP,
    faInstagram,
    faPrint,
    faPlus,
    faMinus,
    faAngleDown,
    faCalendarAlt,
    faClock,
    faLongArrowAltRight,
    faUsers,
    faShip,
    faStar,
    faBars,
    faTiktok
  );

  dom.i2svg();
});
