window.debounce = function debounce(ms, fn) {
  let timeout = null;

  let debouncedFn = (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(_ => fn(...args), ms);
  };

  return debouncedFn;
}
